import React from 'react';
import { Row } from 'react-bootstrap';
import JobCard from '../components/JobCard';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import { SPageH1Title, StyledContainer } from '../containers/Layout/styles';
import Breadcrumbs from '../components/Breadcrumbs';

const Karjera = ({ pageContext, posts = null }) => {
    return (
        <>
            <SEO
                title={`Karjera | FSA`}
                description={''}
                useTitleTemplate={false}
            />
            <StyledContainer fluid>
                <Row>
                    <Breadcrumbs title="Karjera" />
                    <SPageH1Title>Karjera</SPageH1Title>
                    {(posts ? posts : pageContext?.posts).map((job) => (
                        <JobCard
                            officeName={job.darboPasiulymai.filialas}
                            title={job.title}
                            salary={`${job.darboPasiulymai.algosReziai}€`}
                            position={job.darboPasiulymai.pareigos}
                            location={job.darboPasiulymai.miestas}
                            tUrl={`/karjera/${job.slug}/`}
                            lgCol={12}
                            mdCol={12}
                        />
                    ))}
                    <Pagination
                        currentPage={pageContext?.pageNumber}
                        totalPosts={pageContext?.totalPosts}
                        basePath={'/karjera'}
                        pageSize={9}
                    />
                </Row>
            </StyledContainer>
        </>
    );
};

export default Karjera;
