import { Link } from 'gatsby';
import React from 'react';
import { Col } from 'react-bootstrap';
import EURIcon from '../../images/svg/eur_icon.svg';
import LocationIcon from '../../images/svg/location_icon.svg';
import StarIcon from '../../images/svg/star_icon.svg';
import Arrow from '../../images/svg/longArrow.svg';

import {
    SCardBody,
    SCardFooter,
    SCardHeader,
    SCardWrapper,
    SOfficeName,
    SFeature,
    STitle,
    SLink,
    SFeaturesRow
} from './styles';

const JobCard = ({
    officeName,
    title,
    location,
    salary,
    position,
    tUrl,
    lgCol,
    mdCol,
}) => {
    return (
        <Col lg={lgCol} md={mdCol} className="p-0">
            <SCardWrapper>
                <SCardHeader>
                    <SOfficeName>{officeName}</SOfficeName>
                </SCardHeader>
                <SCardBody>
                    <STitle>{title}</STitle>
                </SCardBody>
                <SCardFooter>
                    <SFeaturesRow>
                        <SFeature>
                            <LocationIcon /> {location}
                        </SFeature>
                        <SFeature>
                            <EURIcon /> {salary}
                        </SFeature>
                        <SFeature>
                            <StarIcon /> {position}
                        </SFeature>
                    </SFeaturesRow>
                    <div>
                        <SLink to={tUrl}>
                            Sužinokite daugiau <Arrow />
                        </SLink>
                    </div>
                </SCardFooter>
            </SCardWrapper>
        </Col>
    );
};

export default JobCard;
