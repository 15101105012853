import { Link } from 'gatsby';
import styled from 'styled-components';

export const SCardWrapper = styled.div`
    width: 100%;
    margin-bottom: 24px;
    border-bottom: 1px solid ${(props) => props.theme.color.grey_200};
    padding-bottom: 24px;
`;
export const SCardHeader = styled.div`
    display: block;
`;
export const SCardBody = styled.div`
    display: block;
`;
export const SCardFooter = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const SOfficeName = styled.p`
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.color.grey_900};
`;

export const STitle = styled.h3`
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: ${(props) => props.theme.fontSize.h4};
        line-height: ${(props) => props.theme.lineHeight.h4};
    }
`;

export const SFeature = styled.div`
    display: flex;
    justify-content: center;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    font-size: ${(props) => props.theme.fontSize.regular};
    line-height: ${(props) => props.theme.lineHeight.regular};
    color: ${(props) => props.theme.color.grey_400};
    margin-right: 2rem;

    svg {
        margin: 0 0.5rem 0 0;
        color: ${(props) => props.theme.color.grey_900};

        @media (max-width: 768px) {
            margin: 0 0.5rem 0 0;
        }
    }
`;

export const SLink = styled(Link)`
    color: ${(props) => props.theme.color.primary};
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    
    @media (max-width: 768px) {
        margin-top: 1rem;
    }

    &:hover {
        color: ${(props) => props.theme.color.grey_900};

        svg > path{
            stroke: ${(props) => props.theme.color.grey_900};
        }
    }
`;

export const SFeaturesRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
    }
`;
